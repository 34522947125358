<template>
    <v-container>
        <div v-if="!!this.campaignData">
            <div v-if="this.campaignData.data.status == 'SCHEDULED'" class="counter">
                <span class="remaining time">{{RemainingTime(campaignData.data.send_time)}}</span>
            </div>
        </div>
        <v-container fluid><v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Company Name</th>
                        <th>Phone Number</th>
                        <th>Company Website</th>
                        <th>Category</th>
                        <th class="text-center" v-if="campaignData && campaignData.data.status == 'SENT'">Delivery Status</th>
                        <th class="text-center">Status</th>
                    </tr>
                </thead>
                <edit-campaign v-if="$route.params.edit" :campaignID="$route.params.id"></edit-campaign>
                <view-campaign v-if="!$route.params.edit || $route.params.pending" :campaignID="$route.params.id"></view-campaign>
            </template>
        </v-simple-table></v-container>

        <template v-if="singleTotalPageNumber>1 && !$store.state.loading">
            <div class="text-center" style="width: 100%;margin: 25px 0px;">
                <v-pagination
                v-model="singleCampaignPagination"
                :length="singleTotalPageNumber"
                class="elevation-0 pagination-row"
                ></v-pagination>
            </div>
        </template>

        <hr style="opacity:0.3">
        <div class="actions" v-if="!$route.params.edit && !$route.params.pending && !$route.params.schedule">
            <v-btn class="elevation-0" @click="$router.go(-1)">Back</v-btn>
        </div>
        <div class="actions" v-if="$route.params.edit">
            <v-btn class="elevation-0" @click="$router.go(-1)" :disabled='campaignActionBtnDisabled'>Back</v-btn>
            <v-btn class="elevation-0 primary" :disabled='campaignActionBtnDisabled' @click="updateCampaign" id="updateCampaign">Update</v-btn>
        </div>
        <div class="actions" v-if="$route.params.pending">
            <v-btn class="elevation-0" @click="$router.go(-1)" :disabled='emailSendingInProgress'>Back</v-btn>
            <v-btn class="elevation-0 primary" :disabled='emailSendingInProgress' @click="sendEmail" id="updateCampaign">Send Now</v-btn>
        </div>
        <div class="actions" v-if="$route.params.schedule">
            <v-btn class="elevation-0" @click="$router.go(-1)" :disabled='emailSendingInProgress'>Back</v-btn>
            <v-btn class="elevation-0" @click="cancelConfirmation = true" :disabled='emailSendingInProgress'>Unschedule</v-btn>
            <v-btn class="elevation-0 primary" :disabled='emailSendingInProgress' @click="sendEmail" id="updateCampaign">Send Now</v-btn>
        </div>
        <div class="loading" v-if="loading"><v-progress-circular
                indeterminate
                color="#777BD2"
            ></v-progress-circular></div>

            <transition name="fade-transition"><send-email-confirmation v-if="SendEmailConfirmation" status="send_saved" :batch_id="$route.params.id" :batchName="campaignData.data.name" @close="SendEmailConfirmation = false"></send-email-confirmation></transition>

            <transition name="fade-transition"><schedule-cancel-confirmation :item="campaignData" primaryTxt="Yes" actionName="cancelScheduledCampaign" v-if="cancelConfirmation" @close="cancelConfirmation = false"></schedule-cancel-confirmation></transition>
    </v-container>
</template>
<script>
import EditCampaign from '@/components/EditCampaign.vue';
import ViewCampaign from '@/components/ViewCampaign.vue';
import ScheduleCancelConfirmation from '@/components/scheduleCancelConfirmation.vue';
import sendEmailConfirmation from '@/components/sendEmailConfirmation.vue';
export default {
    name: 'CampaignDetail',
    components: {
        EditCampaign,
        ViewCampaign,
        ScheduleCancelConfirmation,
        sendEmailConfirmation
    },
    data(){
        return{
            cancelConfirmation: false,
            ItemsPerPage: 10,
        }
    },
    computed: {
        campaignActionBtnDisabled(){
            return this.$store.state.campaignActionBtnDisabled;
        },
        campaignActionInProgress(){
            return this.$store.state.campaignActionInProgress;
        },
        emailSendingInProgress(){
            return this.$store.state.emailSendingInProgress;
        },
        campaignData(){
            return this.$store.state.campaignData;
        },
        loading(){
            return this.$store.state.loading;
        },
        SendEmailConfirmation:{
            get(){
                return this.$store.state.SendEmailConfirmation;
            },
            set(value){
                this.$store.state.SendEmailConfirmation = value;
            }
        },
        singleCampaignPagination:{
            get(){
                return this.$store.state.singleCampaignPagination
            },
            set(value){
                this.$store.state.singleCampaignPagination = value
            }
        },
        singleTotalPageNumber(){
            return this.$store.state.singleTotalPageNumber;
        }
        
    },
    methods: {
        updateCampaign(){
            var campaignTemplate = this.$store.state.campaignTemplate;
            document.querySelector('#updateCampaign span').innerHTML = 'Update';
            this.$store.state.campaignActionInProgress = true;
            this.$store.state.campaignActionBtnDisabled = true;
            var updateCampaignData = {
                "user" : this.$store.state.user.attributes.email,
                "status": "new",
                "name": this.$store.state.campaignTitle,
                "template_name": campaignTemplate,
                "clients": this.campaignData.data.clients
            } 
            this.$store.dispatch('updateCampaign', {campaignID: this.campaignData.data.id, campaignData: updateCampaignData});
        },
        sendEmail(){
            /* this.$store.state.emailSendingInProgress = true;
            var EmailData = {
                "user": this.$store.state.user.attributes.email,
                "template_name": this.$store.state.campaignTemplate,
                "batch_id": this.$route.params.id
            }
            this.$store.dispatch('sendEmails', EmailData); */
            this.SendEmailConfirmation = true;
        },
        RemainingTime(countDownDate){
            // var now = new Date().getTime();
            // var timeleft = countDownDate - now;
            // var dayText, hourText, minuteText/*, secondText */;
            // var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
            // var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            // var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
            // //var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
            // (days<2)? dayText = 'Day' : dayText = 'Days';
            // (hours<2)? hourText = 'Hour' : hourText = 'Hours';
            // (minutes<2)? minuteText = 'Minutes' : minuteText = 'Minutes';
            // //(seconds<2)? secondText = 'Seconds' : secondText = 'Seconds';
            // return 'The Campaign is scheduled and will be sent in '+days+' '+dayText+', '+hours+' '+hourText+', '+minutes+' '+minuteText;
            var scheduledDate, scheduledTime, hrs, mins, ampm;
            if(countDownDate){
              scheduledDate = (new Date(countDownDate).getMonth() + 1) +'/'+ new Date(countDownDate).getDate() +'/'+ new Date(countDownDate).getFullYear();
              scheduledTime = new Date(countDownDate);
              if(scheduledTime.getHours() < 10){
                hrs = '0'+scheduledTime.getHours();
              }
              else{
                hrs = scheduledTime.getHours();
              }
              if(scheduledTime.getMinutes() < 10){
                mins = '0'+scheduledTime.getMinutes();
              }
              else{
                mins = scheduledTime.getMinutes();
              }

              if(hrs<12){
                ampm = 'AM';
              }
              else{
                ampm = 'PM';
                hrs = hrs - 12;
              }
              scheduledTime = hrs+':'+mins+' '+ampm
            }
            return 'Campaign is scheduled on '+scheduledDate+' at '+scheduledTime;
        }
    },
    mounted(){
        this.$store.state.loading = true;
        this.$store.state.gettingcampaignInProgress = true
        this.$store.dispatch('getCampaignData', this.$route.params.id);
        this.$store.state.singleCampaignPagination = 1;
        if(this.$store.state.user == null || this.$store.state.user == undefined){
            this.$router.push({path: '/sign-in'});
        }
        else if(this.$route.params.id == undefined){
            this.$router.push({path: '/all-campaigns'});
        }
    }
}
</script>
<style scoped>
.actions{
    text-align: right;
    margin: 20px 0;
}
.actions button.elevation-0, .actions a.elevation-0{
    padding: 20px 60px;
    margin-left: 15px;
}
.loading{
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255,2555,255,0.9);
}
.loading .v-progress-circular{
    height: calc(15vh) !important;
    width: calc(13vw) !important;
    margin: auto;
}
.counter{
    background-color: #FFAA2C;
    color: #fff;
    padding: 15px;
}
</style>