<template>
    <tbody>
        <template v-for="(item, index) in campaignData.clients">
            <tr 
                :key="item.id"
                v-if="
                index >= (singleCampaignPagination - 1) * ItemsPerPage &&
                    index < singleCampaignPagination * ItemsPerPage &&
                    !$store.state.gettingcampaignInProgress
                "
            >
                <td><quick-edit v-model="item.email" :placeholder="'Client Name'" ></quick-edit></td>
                <td><quick-edit v-model="item.company_name" :placeholder="'Comapny Name'" ></quick-edit></td>
                <td><quick-edit v-model="item.phone_number" :placeholder="'Phone Number'" ></quick-edit></td>
                <td><quick-edit v-model="item.link_to_company" :placeholder="'Company Link'" ></quick-edit></td>
                <td><quick-edit v-model="item.category" :placeholder="'Bussiness Category'" ></quick-edit></td>
                <td v-if="item.status" class="text-center">
                    <span v-if="item.status == 'subscribed'" class="material-icons" title="Subscribed">notifications</span>
                    <span v-if="item.status == 'un-subscribed'" class="material-icons" title="Un-Subscribed" style="color: #FF5D6D">notifications_off</span>
                </td>
            </tr>
        </template>
    </tbody>
</template>
<script>
export default {
    name: 'EditCampaign',
    props: ['campaignID'],
    data(){
        return{

        }
    },
    mounted(){
        this.$store.state.loading = true;
        this.$store.state.gettingcampaignInProgress = true
        this.$store.dispatch('getCampaignData', this.campaignID);
        if(this.$store.state.campaignData != ''){
            this.$store.state.campaignData = '';
            this.$store.state.campaignTitle = '';
            this.$store.state.campaignTemplate = '';
        }
    },
    methods:{
        showCampaignData(){
            this.$store.state.loading = true;
            this.$store.state.gettingCampaignInProgress = true
            this.$store.dispatch('getCampaignData', this.campaignID);
        },
    },
    computed:{
        campaignData(){
            var x = '';
            if(this.$store.state.campaignData == ''){
                this.showCampaignData();
            }
            if(this.$store.state.campaignData){
                x = this.$store.state.campaignData.data
            }
            return x;
        },
        singleCampaignPagination() {
            return this.$store.state.singleCampaignPagination;
        },
        ItemsPerPage() {
            return this.$store.state.singleItemsPerPage;
        },
    }
}
</script>
<style scoped>

</style>