<template>
  <tbody>
    <template v-for="(item, index) in campaignData.clients">
      <tr
        :key="item.id"
        v-if="
          index >= (singleCampaignPagination - 1) * ItemsPerPage &&
            index < singleCampaignPagination * ItemsPerPage &&
            !$store.state.gettingcampaignInProgress
        "
      >
        <td>{{ item.email }}</td>

        <td>{{ item.company_name }}</td>
        <td>{{ item.phone_number }}</td>
        <td>{{ item.link_to_company }}</td>
        <td>{{ item.category }}</td>
        <td
          v-if="
            item.delivery_status == 1 &&
              campaignData.status == 'SENT' &&
              item.status == 'subscribed'
          "
          class="text-center"
        >
          Opened
        </td>
        <td
          v-if="
            (!item.delivery_status || item.delivery_status == 0) &&
              campaignData.status == 'SENT' &&
              item.status == 'subscribed'
          "
          class="text-center"
        >
          Sent
        </td>
        <td
          v-if="
            (!item.delivery_status || item.delivery_status == 0) &&
              campaignData.status == 'SENT' &&
              item.status == 'un-subscribed'
          "
          class="text-center"
        >
          Not Delivered
        </td>
        <td
          v-if="
            (!item.delivery_status || item.delivery_status == 0) &&
              campaignData.status == 'SENT' &&
              (!item.status || item.status == 'undefined')
          "
          class="text-center"
        >
          -
        </td>
        <td v-if="item.status" class="text-center">
          <span
            v-if="item.status == 'subscribed'"
            class="material-icons"
            title="Subscribed"
            >notifications</span
          >
          <span
            v-if="item.status == 'un-subscribed'"
            class="material-icons"
            title="Un-Subscribed"
            style="color: #FF5D6D"
            >notifications_off</span
          >
        </td>
        <td v-if="!item.status || item.status == 'undefined'" class="text-center">
          -
        </td>
      </tr>
    </template>
    <tr v-if="campaignData.status == 'SENT'"></tr>
  </tbody>
</template>
<script>
export default {
  name: "ViewCampaign",
  props: ["campaignID"],
  data() {
    return {};
  },
  mounted() {
    if (this.$store.state.campaignData != "") {
      this.$store.state.campaignData = "";
      this.$store.state.campaignTitle = "";
      this.$store.state.campaignTemplate = "";
    }
  },
  methods: {
    showcampaignData() {
      this.$store.state.loading = true;
      this.$store.state.gettingcampaignInProgress = true;
      this.$store.dispatch("getCampaignData", this.campaignID);
    },
  },
  computed: {
    campaignData() {
      var x = "";
      if (this.$store.state.campaignData == "") {
        this.showcampaignData();
      }
      if (this.$store.state.campaignData) {
        x = this.$store.state.campaignData.data;
      }
      return x;
    },
    ItemsPerPage() {
      return this.$store.state.singleItemsPerPage;
    },
    singleCampaignPagination() {
      return this.$store.state.singleCampaignPagination;
    }
  }
};
</script>
<style scoped></style>
