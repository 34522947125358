var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbody',[_vm._l((_vm.campaignData.clients),function(item,index){return [(
        index >= (_vm.singleCampaignPagination - 1) * _vm.ItemsPerPage &&
          index < _vm.singleCampaignPagination * _vm.ItemsPerPage &&
          !_vm.$store.state.gettingcampaignInProgress
      )?_c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.email))]),_c('td',[_vm._v(_vm._s(item.company_name))]),_c('td',[_vm._v(_vm._s(item.phone_number))]),_c('td',[_vm._v(_vm._s(item.link_to_company))]),_c('td',[_vm._v(_vm._s(item.category))]),(
          item.delivery_status == 1 &&
            _vm.campaignData.status == 'SENT' &&
            item.status == 'subscribed'
        )?_c('td',{staticClass:"text-center"},[_vm._v(" Opened ")]):_vm._e(),(
          (!item.delivery_status || item.delivery_status == 0) &&
            _vm.campaignData.status == 'SENT' &&
            item.status == 'subscribed'
        )?_c('td',{staticClass:"text-center"},[_vm._v(" Sent ")]):_vm._e(),(
          (!item.delivery_status || item.delivery_status == 0) &&
            _vm.campaignData.status == 'SENT' &&
            item.status == 'un-subscribed'
        )?_c('td',{staticClass:"text-center"},[_vm._v(" Not Delivered ")]):_vm._e(),(
          (!item.delivery_status || item.delivery_status == 0) &&
            _vm.campaignData.status == 'SENT' &&
            (!item.status || item.status == 'undefined')
        )?_c('td',{staticClass:"text-center"},[_vm._v(" - ")]):_vm._e(),(item.status)?_c('td',{staticClass:"text-center"},[(item.status == 'subscribed')?_c('span',{staticClass:"material-icons",attrs:{"title":"Subscribed"}},[_vm._v("notifications")]):_vm._e(),(item.status == 'un-subscribed')?_c('span',{staticClass:"material-icons",staticStyle:{"color":"#FF5D6D"},attrs:{"title":"Un-Subscribed"}},[_vm._v("notifications_off")]):_vm._e()]):_vm._e(),(!item.status || item.status == 'undefined')?_c('td',{staticClass:"text-center"},[_vm._v(" - ")]):_vm._e()]):_vm._e()]}),(_vm.campaignData.status == 'SENT')?_c('tr'):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }